import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    border: 1px solid ",
        ";\n    border-radius: ",
        ";\n    padding: ",
        ";\n    gap: ",
        ";\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      width: ",
        ";\n    }\n\n    hr {\n      background-color: ",
        ";\n    }\n\n    p:last-child {\n      margin-bottom: 0;\n    }\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    border: none;\n    border-radius: ",
        ";\n    padding: ",
        ";\n    gap: ",
        ";\n    box-shadow: 0 2px 8px rgb(0 0 0 / 0.16);\n    background-color: ",
        ";\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n      gap: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n      gap: ",
        ";\n    }\n\n    hr {\n      background-color: ",
        ";\n    }\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: fit-content;\n\n  hr {\n    height: ",
        ";\n    border: none;\n    width: 100%;\n    margin: 0 auto;\n\n    &:nth-last-child(2), &:last-child{\n      display: none;\n    }\n  }\n\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, colors, pxToRem } from "@vfit/shared/themes";
import styled, { css } from "styled-components";
var variants = {
    sunrise: css(_templateObject(), colors.$bebebe, pxToRem(20), pxToRem(32, 24), pxToRem(32), breakpoints.tablet, pxToRem(327), breakpoints.desktop, pxToRem(405), colors.$bebebe),
    mva: css(_templateObject1(), pxToRem(6), pxToRem(20, 16), pxToRem(24), colors.$ffffff, breakpoints.tablet, pxToRem(24, 16), pxToRem(28), breakpoints.desktop, pxToRem(32, 16), pxToRem(32), colors.$f2f2f2)
};
export var Container = styled.div.withConfig({
    componentId: "sc-e7807974-0"
})(_templateObject2(), pxToRem(1), function(param) {
    var variant = param.variant;
    return variants[variant || "sunrise"];
});
