import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border: none;\n  cursor: pointer;\n  height: ",
        ";\n  padding: 0;\n  visibility: ",
        ";\n  width: ",
        ";\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n    width: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var ButtonContainer = styled.button.withConfig({
    componentId: "sc-146eb238-0"
})(_templateObject(), pxToRem(32), function(param) {
    var visible = param.visible;
    return visible ? "visible" : "hidden";
}, pxToRem(32), breakpoints.tablet, pxToRem(36), pxToRem(36));
