import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  height: ",
        ";\n  left: 0;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  padding: ",
        ";\n  z-index: 2;\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin: 0 auto;\n  justify-content: space-between;\n  align-items: center;\n  height: 100%;\n\n  @media (min-width: ",
        ") {\n    max-width: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, pxToRem, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var Header = styled.header.withConfig({
    componentId: "sc-e9c194b9-0"
})(_templateObject(), colors.$ffffff, pxToRem(80), pxToRem(36, 8, 16), breakpoints.tablet, pxToRem(36, 12, 16));
export var Nav = styled.nav.withConfig({
    componentId: "sc-e9c194b9-1"
})(_templateObject1(), breakpoints.desktop, pxToRem(1140));
export var Title = styled.div.withConfig({
    componentId: "sc-e9c194b9-2"
})(_templateObject2(), fonts.regular, pxToCssFont(18, 28), breakpoints.tablet, pxToCssFont(20, 28));
