import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n\n  > section + section {\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n    margin: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        ";\n  color: ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, colors, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-7c287b0f-0"
})(_templateObject(), pxToRem(16), pxToRem(24));
export var Title = styled.h4.withConfig({
    componentId: "sc-7c287b0f-1"
})(_templateObject1(), pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(20, 30), pxToRem(-2, 0));
export var Data = styled.p.withConfig({
    componentId: "sc-7c287b0f-2"
})(_templateObject2(), pxToCssFont(18, 24), colors.$e60000, breakpoints.tablet, pxToCssFont(20, 28));
