import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  background-color: ",
        ";\n  margin-top: ",
        ";\n  padding: ",
        ";\n\n  @media (min-width: ",
        ") {\n    width: 50%;\n    margin-top: 0;\n  }\n\n  img {\n    max-height: ",
        ";\n    object-fit: contain !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  position: absolute;\n  bottom: ",
        ";\n  right: ",
        ";\n  cursor: pointer;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, colors, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var ImageBox = styled.div.withConfig({
    componentId: "sc-a0f276f-0"
})(_templateObject(), colors.$ebebeb, pxToRem(80), pxToRem(20, 16), breakpoints.desktop, pxToRem(350));
export var IconBox = styled.figure.withConfig({
    componentId: "sc-a0f276f-1"
})(_templateObject1(), pxToRem(29), pxToRem(16));
