import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: ",
        ";\n  box-shadow: 0 2px 8px rgb(0, 0, 0, 16%);\n  gap: ",
        ";\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    align-items: stretch;\n    display: flex;\n    flex-direction: ",
        ";\n    gap: ",
        ";\n    padding: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n    gap: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  width: 100%;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: 50%;\n  border-radius: ",
        ";\n\n  @media (min-width: ",
        ") {\n    height: auto;\n    width: 50%;\n    min-height: ",
        ";\n    margin: 0;\n    border-radius: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    width: ",
        ";\n    background-size: ",
        ";\n    background-color: ",
        ";\n    border-top-left-radius: ",
        ";\n  }\n\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: none;\n\n  @media (min-width: ",
        ") {\n    display: block;\n    height: ",
        ";\n    width: 100%;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: 50%;\n    margin: 0;\n    border-top-left-radius: ",
        ";\n  }\n\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n  justify-content: space-between;\n  padding: ",
        ";\n\n  button:not(:last-of-type) {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 50%;\n    padding: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    width: ",
        ";\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import { pxToRem, colors, getTypography, breakpoints } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.article.withConfig({
    componentId: "sc-633e005c-0"
})(_templateObject(), colors.$ffffff, pxToRem(6), pxToRem(16), breakpoints.tablet, function(param) {
    var imageToTheLeft = param.imageToTheLeft;
    return imageToTheLeft ? "row" : "row-reverse";
}, pxToRem(24), function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? 0 : pxToRem(24);
}, breakpoints.desktop, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? 0 : pxToRem(24);
}, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? 0 : pxToRem(24);
});
export var ImageDiv = styled.div.withConfig({
    componentId: "sc-633e005c-1"
})(_templateObject1(), pxToRem(120), pxToRem(6, 6, 0, 0), breakpoints.tablet, pxToRem(210), breakpoints.desktop, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? "38%" : "50%";
}, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? "unset" : "cover";
}, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? colors.$f2f2f2 : "unset";
}, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? "0" : pxToRem(6);
}, function(param) {
    var srcSetObjCss = param.srcSetObjCss;
    return srcSetObjCss;
});
export var HeaderImage = styled.div.withConfig({
    componentId: "sc-633e005c-2"
})(_templateObject2(), breakpoints.desktop, pxToRem(120), pxToRem(6), function(param) {
    var srcSetObjCss = param.srcSetObjCss;
    return srcSetObjCss;
});
export var BodyWrap = styled.div.withConfig({
    componentId: "sc-633e005c-3"
})(_templateObject3(), pxToRem(16), pxToRem(16), breakpoints.tablet, breakpoints.desktop, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? "100%" : "50%";
}, function(param) {
    var variant = param.variant;
    return variant === "oneclick" ? pxToRem(16, 16) : 0;
});
export var Title = styled.h5.withConfig({
    componentId: "sc-633e005c-4"
})(_templateObject4(), getTypography("h5.regular"));
export var Description = styled.span.withConfig({
    componentId: "sc-633e005c-5"
})(_templateObject5(), getTypography("body1.regular"));
