import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  height: 442px;\n\n  @media (min-width: ",
        ") {\n    height: 800px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 680px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: 0 0 20px 20px;\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    display: inline-block;\n    background: linear-gradient(1.14deg, rgba(0, 0, 0, 4%) 11.28%, rgba(0, 0, 0, 0%) 75.72%);\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n  padding-left: 30px;\n  margin-bottom: 28px;\n  display: flex;\n  align-items: center;\n\n  .back {\n    width: inherit;\n    display: flex;\n    cursor: pointer;\n    margin: 0;\n    appearance: none;\n    background: none;\n    border: none;\n    font-family: inherit;\n    font-size: inherit;\n    padding: 0;\n  }\n\n  div {\n    transform: rotate(180deg);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 9px;\n      height: 16px;\n\n      path {\n        stroke: white;\n      }\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: white;\n    margin-left: 15px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 67px;\n    padding-left: 65px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 56px;\n    padding-left: 80px;\n    margin-bottom: inherit;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.8;\n\n  @media (min-width: ",
        ") {\n    flex: 0.9;\n  }\n\n  @media (min-width: ",
        ") {\n    flex: 0.8;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.2;\n  display: flex;\n  justify-content: center;\n  padding-bottom: 60px;\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 0;\n    flex: 0.1;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 10px;\n    flex: 0.2;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: 324px;\n  max-width: 400px;\n  padding-bottom: 25px;\n\n  div {\n    width: 324px;\n    max-width: 400px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  h2 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    letter-spacing: ",
        ";\n    text-transform: uppercase;\n    color: ",
        ";\n    margin: 0 30px;\n  }\n\n  .title div {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    // TODO FIX THIS WITH DESIGN STUDIO\n    padding-top: ",
        ";\n    @media (min-width: ",
        ") {\n      padding-top: 0px;\n    }\n    max-width: none;\n    p {\n      margin: inherit;\n    }\n  }\n\n  h4 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 30px 30px 0;\n    max-width: none;\n  }\n\n  h5 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 700;\n    color: ",
        ";\n    margin: 8px 30px 0;\n    max-width: none;\n  }\n\n  div {\n    button {\n      margin-left: 30px;\n    }\n  }\n\n  .text {\n    div {\n      font-family: ",
        ";\n      ",
        "\n      font-weight: 400;\n      color: ",
        ";\n      margin: 8px 30px 0;\n      max-width: none;\n      margin-top: 0 !important;\n    }\n\n    span {\n      display: flex;\n      font-weight: 400;\n      color: ",
        ";\n      align-items: baseline;\n\n      .price {\n        font-family: ",
        ";\n        margin: 8px 0 0 30px;\n        ",
        ";\n\n        @media (min-width: ",
        ") {\n          ",
        "\n        }\n      }\n\n      .rec {\n        font-family: ",
        ";\n        margin: 8px 0 0;\n        ",
        ";\n\n        @media (min-width: ",
        ") {\n          ",
        "\n        }\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 32px;\n    padding-top: ",
        ";\n\n    h2 {\n      ",
        "\n    }\n\n    .title div {\n      ",
        "\n      max-width: 620px;\n      p {\n        margin: inherit;\n      }\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n      max-width: 70%;\n    }\n\n    .text {\n      div {\n        ",
        "\n        max-width: 70%;\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 50px;\n    padding-top: ",
        ";\n\n    h2 {\n      ",
        "\n    }\n\n    .title div {\n      ",
        "\n      max-width: 670px;\n      p {\n        margin: inherit;\n      }\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n      max-width: 70%;\n    }\n\n    .text {\n      div {\n        ",
        "\n        max-width: 70%;\n      }\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 30px;\n  padding-bottom: 30px;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
export var SlideProductContainer = styled.div.withConfig({
    componentId: "sc-66adcce5-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-66adcce5-1"
})(_templateObject1(), function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
}, breakpoints.tablet, breakpoints.desktop);
export var SlideProductNavigation = styled.div.withConfig({
    componentId: "sc-66adcce5-2"
})(_templateObject2(), fonts.regular, pxToCssFontSize(16), breakpoints.tablet, breakpoints.desktop);
export var SlideProductContent = styled.div.withConfig({
    componentId: "sc-66adcce5-3"
})(_templateObject3());
export var SlideProductContentTop = styled.div.withConfig({
    componentId: "sc-66adcce5-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductContentBottom = styled.div.withConfig({
    componentId: "sc-66adcce5-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-66adcce5-6"
})(_templateObject6());
export var SlideProductText = styled.div.withConfig({
    componentId: "sc-66adcce5-7"
})(_templateObject7(), fonts.regular, pxToCssFont(16, 24), pxToRem(0.5), colors.$ffffff, fonts.exbold, pxToCssFont(42, 40), colors.$ffffff, function(param) {
    var paddingControl = param.paddingControl;
    return paddingControl ? "32px" : "0px";
}, breakpoints.tablet, fonts.exbold, pxToCssFont(24, 28), colors.$f2f2f2, fonts.regular, pxToCssFont(18, 24), colors.$bebebe, fonts.regular, pxToCssFont(18, 24), colors.$f2f2f2, colors.$ffffff, fonts.exbold, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(42, 52), fonts.exbold, pxToCssFont(24, 28), breakpoints.desktop, pxToCssFont(36, 45), breakpoints.tablet, function(param) {
    var enablePaddingTop = param.enablePaddingTop;
    return enablePaddingTop ? "61px" : "7px";
}, pxToCssFont(18, 24), pxToCssFont(64, 80), pxToCssFont(30, 38), pxToCssFont(20, 30), pxToCssFont(14, 18), breakpoints.desktop, function(param) {
    var enablePaddingTop = param.enablePaddingTop;
    return enablePaddingTop ? "56px" : "7px";
}, pxToCssFont(18, 24), pxToCssFont(70, 65), pxToCssFont(42, 52), pxToCssFont(20, 30), pxToCssFont(18, 24));
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-66adcce5-8"
})(_templateObject8());
export var ModalContent = styled.div.withConfig({
    componentId: "sc-66adcce5-9"
})(_templateObject9());
export var SlideButtons = styled.div.withConfig({
    componentId: "sc-66adcce5-10"
})(_templateObject10());
