import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { purify } from "@vfit/shared/themes";
export var useFrameEditorialInput = function(param) {
    var _description = param.description, description = _description === void 0 ? "" : _description;
    var ref = _sliced_to_array(purify([
        description
    ]), 1), pDescription = ref[0];
    return {
        pDescription: pDescription
    };
};
