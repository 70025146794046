import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  row-gap: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  ",
        "\n  margin:0;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-top: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, cssfonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-1fdc9a0e-0"
})(_templateObject(), pxToRem(3));
export var Title = styled.h3.withConfig({
    componentId: "sc-1fdc9a0e-1"
})(_templateObject1(), cssfonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30));
export var Description = styled.span.withConfig({
    componentId: "sc-1fdc9a0e-2"
})(_templateObject2(), pxToCssFont(16, 22), pxToRem(3), breakpoints.tablet, pxToCssFont(18, 24), function(props) {
    return props.hasIcon ? "margin-top: 0;" : "";
});
