import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { nextClient } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
export var attachmentDownload = function(documentId, salesChannel, filters, customOptions) {
    return nextClient.get("".concat(API.ATTACHMENT_DOWNLOAD_DOCUMENT, "/").concat(documentId), _object_spread({
        notJsonParsing: true,
        searchParams: {
            salesChannel: salesChannel,
            filters: filters
        }
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
