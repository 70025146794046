import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  bottom: 0;\n  min-height: ",
        ";\n  left: 0;\n  padding: ",
        ";\n  position: fixed;\n  width: 100%;\n  z-index: 2;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  max-width: ",
        ";\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: flex-end;\n\n    > * {\n      flex: 0 1 ",
        ";\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { pxToRem, breakpoints } from "@vfit/shared/themes";
import styled from "styled-components";
export var Footer = styled.footer.withConfig({
    componentId: "sc-6ac341d5-0"
})(_templateObject(), function(param) {
    var bg = param.bg;
    return bg;
}, pxToRem(76), pxToRem(16));
export var Box = styled.div.withConfig({
    componentId: "sc-6ac341d5-1"
})(_templateObject1(), pxToRem(1140), breakpoints.tablet, pxToRem(200));
