import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n      ",
        ";\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { purify } from "@vfit/shared/themes";
import { requireStaticImageAtomComponent } from "@vfit/shared/data-access";
import { css } from "styled-components";
/**
 * take the name of the image and give back an elaborated srcSet object
 * @param image: string
 * @returns srcSet broken down into an object with url, minimun resolution and maximum reso lution in which the src has to be used
 */ export var getSrcSetObject = function(image) {
    var ref;
    var srcSet = requireStaticImageAtomComponent(image || "").srcSet;
    var srcSetArray = ((ref = srcSet === null || srcSet === void 0 ? void 0 : srcSet.split(",")) === null || ref === void 0 ? void 0 : ref.map(function(s) {
        return s === null || s === void 0 ? void 0 : s.substring(0, s.length - 1).split(" ");
    })) || [];
    var srcSetObject = (srcSetArray === null || srcSetArray === void 0 ? void 0 : srcSetArray.map(function(elem, index) {
        var ref;
        return {
            url: elem === null || elem === void 0 ? void 0 : elem[0],
            minResolution: index === 0 ? "0" : srcSetArray === null || srcSetArray === void 0 ? void 0 : (ref = srcSetArray[index - 1]) === null || ref === void 0 ? void 0 : ref[1],
            // eslint-disable-next-line no-unsafe-optional-chaining
            maxResolution: index === (srcSetArray === null || srcSetArray === void 0 ? void 0 : srcSetArray.length) - 1 ? "0" : elem === null || elem === void 0 ? void 0 : elem[1]
        };
    })) || [];
    return srcSetObject;
};
/**
 * take the name of the image and give back a FlattenSimpleInterpolation object of the resulted srcSet, in order to simulate the behavior of an <img/> html srcSet, but for a background image
 * @param image: string
 * @returns FlattenSimpleInterpolation of n mediaQueries for each srcSet resolution
 */ export var getSrcSetObjectCss = function(image) {
    var srcSetObject = getSrcSetObject(image);
    return {
        css: css(_templateObject(), srcSetObject && srcSetObject.map(function(src) {
            return "\n  @media (min-width: ".concat(src === null || src === void 0 ? void 0 : src.minResolution, "px) ").concat((src === null || src === void 0 ? void 0 : src.maxResolution) !== "0" ? "and (max-width: ".concat(src === null || src === void 0 ? void 0 : src.maxResolution, "px)") : "", " {\n    background-image: url(").concat(src === null || src === void 0 ? void 0 : src.url, ")};\n  }\n  ");
        }))
    };
};
export var useVodafoneWorldCard = function(description, image) {
    var ref = _sliced_to_array(purify([
        description
    ]), 1), purifiedDescription = ref[0];
    var srcSetObjCss = getSrcSetObjectCss(image);
    return {
        purifiedDescription: purifiedDescription,
        srcSetObjCss: srcSetObjCss
    };
};
