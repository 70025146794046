import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    min-height: 100%;\n    padding: ",
        ";\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n    }\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    min-height: 100%;\n    padding: ",
        ";\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n    }\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    min-height: 100%;\n    padding: ",
        ";\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n    }\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    min-height: 100%;\n    padding: ",
        ";\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n    }\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    min-height: 100%;\n    padding: ",
        ";\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n    }\n  "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n    min-height: 100%;\n    padding: ",
        ";\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n    }\n  "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n    padding: ",
        ";\n\n    @media (min-width: ",
        ") {\n      padding: ",
        ";\n    }\n  "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  max-width: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { pxToRem, breakpoints } from "@vfit/shared/themes";
var e = {
    navbar: 80,
    footer: 77,
    footer2Btns: 132,
    tabs: 50
};
var variants = {
    navbar: css(_templateObject(), pxToRem(e.navbar + 32, 16, 32), breakpoints.tablet, pxToRem(e.navbar + 32, 16, 40)),
    "navbar-footer": css(_templateObject1(), pxToRem(e.navbar + 32, 16, e.footer + 32), breakpoints.tablet, pxToRem(e.navbar + 32, 16, e.footer + 40)),
    "navbar-tabs-footer": css(_templateObject2(), pxToRem(e.navbar + e.tabs + 32, 16, e.footer2Btns + 32), breakpoints.tablet, pxToRem(e.navbar + e.tabs + 32, 16, e.footer + 40)),
    "navbar-footer-with2btn": css(_templateObject3(), pxToRem(e.navbar + 32, 16, e.footer2Btns + 32), breakpoints.tablet, pxToRem(e.navbar + 32, 16, e.footer + 40)),
    "navbar-tabs-footer-with2btn": css(_templateObject4(), pxToRem(e.navbar + e.tabs + 32, 16, e.footer + 32), breakpoints.tablet, pxToRem(e.navbar + e.tabs + 32, 16, e.footer + 40)),
    dashboard: css(_templateObject5(), pxToRem(32, 16), breakpoints.tablet, pxToRem(32, 16, 24)),
    "dashboard-without-dot-stepper": css(_templateObject6(), pxToRem(0, 16, 32), breakpoints.tablet, pxToRem(0, 16, 24))
};
export var Main = styled.main.withConfig({
    componentId: "sc-91f48e3d-0"
})(_templateObject7(), function(param) {
    var bg = param.bg;
    return bg ? "background-color: ".concat(bg, ";") : "";
}, function(param) {
    var variant = param.variant;
    return variant ? variants[variant] : "";
}, function(param) {
    var center = param.center;
    return center ? "display: flex; align-items: center;" : "";
});
export var Container = styled.div.withConfig({
    componentId: "sc-91f48e3d-1"
})(_templateObject8(), pxToRem(1140), function(param) {
    var center = param.center;
    return center ? "height: 100%; width: 100%" : "";
});
