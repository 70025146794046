import { useEffect } from "react";
import { AppMobile } from "@vfit/shared/data-access";
export var useNavBar = function(param) {
    var backInApp = param.backInApp;
    var isInApp = AppMobile.checkIsApp();
    useEffect(function() {
        if (isInApp && backInApp) {
            AppMobile.setBackButtonUrl(window.location.href);
        }
    }, [
        isInApp,
        backInApp
    ]);
    return {
        isInApp: isInApp
    };
};
