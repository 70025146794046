import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    margin: ",
        ";\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    margin: ",
        ";\n    ",
        "\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    margin: ",
        ";\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    font-weight: 300;\n    ",
        "\n    margin: ",
        ";\n  "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n    margin: ",
        ";\n    font-weight: 400;\n    ",
        "\n  "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin:0;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: fit-content;\n  ",
        "\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  p {\n    margin: 0;\n  }\n\n  ",
        "\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
import { getTypography, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled, { css } from "styled-components";
var resh = {
    wrapPill: css(_templateObject()),
    title: css(_templateObject1(), getTypography("h3.extrabold"), pxToRem(0, 0, 24, 0)),
    info: css(_templateObject2(), pxToRem(16, 0, 0, 0), getTypography("body3.regular"))
};
var onb = {
    wrapPill: css(_templateObject3(), pxToRem(0, 0, 16, 0)),
    title: css(_templateObject4(), pxToCssFont(24, 32), pxToRem(0, 0, 16, 0)),
    info: css(_templateObject5(), pxToRem(16, 0, 0, 0), pxToCssFont(18, 24))
};
export var getVariant = function(component, variant) {
    switch(variant){
        case "resh":
            return resh[component];
        default:
            return onb[component];
    }
};
export var Title = styled.h2.withConfig({
    componentId: "sc-67b83cc7-0"
})(_templateObject6(), function(param) {
    var variant = param.variant;
    return getVariant("title", variant);
});
export var TextPill = styled.h3.withConfig({
    componentId: "sc-67b83cc7-1"
})(_templateObject7(), getTypography("body1.bold"));
export var WrapPill = styled.h3.withConfig({
    componentId: "sc-67b83cc7-2"
})(_templateObject8(), function(param) {
    var variant = param.variant;
    return getVariant("wrapPill", variant);
});
export var Info = styled.div.withConfig({
    componentId: "sc-67b83cc7-3"
})(_templateObject9(), function(param) {
    var variant = param.variant;
    return getVariant("info", variant);
});
