import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  iframe {\n    height: ",
        ";\n    width: ",
        ";\n\n    @media (min-width: ",
        ") {\n      height: ",
        ";\n      width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      height: ",
        ";\n      width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var PlayerWrap = styled.div.withConfig({
    componentId: "sc-44d749e6-0"
})(_templateObject(), function(param) {
    var height = param.height;
    return pxToRem((height === null || height === void 0 ? void 0 : height.mobile) || 210);
}, function(param) {
    var width = param.width;
    return pxToRem((width === null || width === void 0 ? void 0 : width.mobile) || "100%");
}, breakpoints.tablet, function(param) {
    var height = param.height;
    return pxToRem((height === null || height === void 0 ? void 0 : height.tablet) || 328);
}, function(param) {
    var width = param.width;
    return pxToRem((width === null || width === void 0 ? void 0 : width.tablet) || "100%");
}, breakpoints.desktop, function(param) {
    var height = param.height;
    return pxToRem((height === null || height === void 0 ? void 0 : height.desktop) || 350);
}, function(param) {
    var width = param.width;
    return pxToRem((width === null || width === void 0 ? void 0 : width.desktop) || "100%");
});
